/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAJB1u4AN0n_i8O2gqQ_ntzK0IQC19MqVM",
  "appId": "1:610523074177:web:0d1b46a4c6cbc31f014780",
  "authDomain": "schooldog-i-dublin-ga.firebaseapp.com",
  "measurementId": "G-JKFQN4R636",
  "messagingSenderId": "610523074177",
  "project": "schooldog-i-dublin-ga",
  "projectId": "schooldog-i-dublin-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-dublin-ga.appspot.com"
}
